.login__container--background {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url('../assets/images/logo/fondo.svg');
}

/* Scrollbar */
:root {
  scrollbar-width: thin;
  scrollbar-color: #1474b8 #ffffff;
  scrollbar-face-color: #1474b8;
  scrollbar-shadow-color: #ffffff;
}

body::-webkit-scrollbar {
  width: 10px;
  color: #1474b8;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #1474b8;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.hide-scrollbar {
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-bg-1 {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: linear-gradient(
      30deg,
      #1474b8 12%,
      transparent 12.5%,
      transparent 87%,
      #1474b8 87.5%,
      #1474b8
    ),
    linear-gradient(150deg, #1474b8 12%, transparent 12.5%, transparent 87%, #1474b8 87.5%, #1474b8),
    linear-gradient(30deg, #1474b8 12%, transparent 12.5%, transparent 87%, #1474b8 87.5%, #1474b8),
    linear-gradient(150deg, #1474b8 12%, transparent 12.5%, transparent 87%, #1474b8 87.5%, #1474b8),
    linear-gradient(60deg, #f0852b 25%, transparent 25.5%, transparent 75%, #f0852b 75%, #f0852b),
    linear-gradient(60deg, #f0852b 25%, transparent 25.5%, transparent 75%, #f0852b 75%, #f0852b);
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

/* Chrome, Safari, Edge, Opera */
.remove-arrows input::-webkit-outer-spin-button,
.remove-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.remove-arrows input[type='number'] {
  -moz-appearance: textfield;
}

@media (min-width: 768px) {

  .sidebar-narrow-unfoldable:not(:hover), .sidebar-narrow {
      padding-bottom: 0;
  }
}
